<!-- 校园安全-体温监测 -->
<template>
    <div>
        <div v-if="!errorShow" class="right-panel">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow" class="content-wrapper">
                <template>
                    <!-- 顶部筛选 -->
                    <expand-filter
                        label-width="80px"
                        :formData="formData"
                        @clickBtn="clickBtn"
                        @treeClick="handleTreeClick"
                        @treeClear="handleTreeClear"
                        @changeBtnFormType="changeBtnFormType"
                    />
                    <div
                        style=" box-sizing: border-box;padding: 10px 30px 10px 20px; overflow: hidden; background-color: #fff;">
                        <div style="float: right">
                            <div class="text-div">
                                <div class="text-div" style="margin-right: 100px">
                                    <span class="normal-text">体温高出：</span>
                                    <el-input-number v-model="tempurate" :precision="1" :max="42" :min="35"
                                                     @input="handleTempurateInput" style="width: 110px"
                                                     size="mini"></el-input-number>
                                    <span class="normal-text">°C属于<span style="color: #f56c6c">异常</span></span></div>
                                <div class="text-div" style="margin-right: 10px"><span
                                    class="normal-text">检查人数：</span><span class="normal-text">{{
                                        tableData.length
                                    }}人</span></div>
                                <div class="text-div" style="color: red;margin-right: 10px"><span class="normal-text">体温异常：</span><span
                                    class="normal-text" style="color: #f56c6c">{{
                                        tableData.filter(data => data.temperatureState == 0).length
                                    }}人</span></div>
                                <xk-button type="primary" text @click="exportAll" v-hasPermi="['temperatureMonitor:export']">
                                    导出<img :src="exportUrl" class="img" alt="export">
                                </xk-button>
                            </div>
                        </div>
                    </div>

                    <!-- 表格 -->
                    <table-data
                        id="table"
                        ref="table"
                        v-loading="loadingTable"
                        :config="tableConfig"
                        :tableData="tableData"
                        @handleSelectionChange="handleSelectionChange"
                    ></table-data>
                    <!-- 分页组件 -->
                    <pagination
                        :total="total"
                        :page.sync="listQuery.pageNum"
                        :limit.sync="listQuery.pageRow"
                        @pagination="getList"
                    />
                </template>
            </div>
        </div>
        <error :errorShow="errorShow"></error>
    </div>
</template>

<script>
import {
    // 业务组件
    FilterData,
    TableData,
    DialogWrapper,
    Pagination,
    // 功能组件
    Error,
    Loading,
    debounce
} from 'common-local';
import {
    downloadFile,
} from '@/libs/utils.js'
import SelTree from "./Sub/SelTree";
import ExpandFilter from "./Sub/ExpandFilter";
import {
    mapState
} from 'vuex';
// 自定义颜色
import btnSty from '@/mixins/btnSty.js'
import calendar from "./CampusSafetyAccessManager/accessSetting/calendar";
// 静态数据
import temperatureMonitoring from '@/assets/data/temperatureMonitoring'
// Ajax
import {TemperatureMonitoringModel} from '@/models/TemperatureMonitoring'

export default {
    mixins: [btnSty],
    name: 'CampusSafetyAccessManageAccessSettingScroll',
    components: {
        ExpandFilter,
        TableData,
        DialogWrapper,
        // 功能组件
        Error,
        Loading,
        Pagination,
        calendar,
        SelTree
    },
    data() {
        return {
            listQuery: {
                schoolId: '',
                pageNum: 1,
                pageRow: 20,
                outliers: '',
                temperatureState: '',
                personName: '',
                dateRange: '',
                startTime: '',
                endTime: '',
                organId: '',
                addressId: '',
                addressName: '',
                personIdentity: '',
            },
            tempurate: '37.3',
            checkNum: '',
            warningNum: '',
            total: 0,
            formData: {},
            tableConfig: {},
            // 表格数据
            tableData: [],
            // 表格选中
            tableListSel: [],
            addressNameList: [],
            // 功能组件
            errorShow: false,
            loadingShow: false,
            loadingTable: true,

        }
    },
    computed: {
        ...mapState({
            schoolId: state => state.schoolId,
        }),
        backUrl() {
            return require('@/assets/images/back_.png')
        },
        exportUrl() {
            return require('@/assets/images/export.png')
        },

    },
    created() {

        this.formData = temperatureMonitoring.baseData.formData
        this.tableConfig = temperatureMonitoring.baseData.table_config
        // 获取列表数据
        this.getList()
        // 获取学生数据
        this.getTreeData()
        this.getOrganData()
    },
    mounted() {
        // 表格动态调整高度
        this.changeTableHeight()
    },
    methods: {
        getList(t) {
            if (t === 1) {
                this.listQuery.pageNum = 1
            }
            this.loadingTable = true
            let temperatureMonitoringModel = new TemperatureMonitoringModel();
            this.listQuery.schoolId = this.schoolId
            temperatureMonitoringModel.getTemperatureList(this.listQuery).then((res) => {
                this.handleRes(res, () => {
                    this.tableData = res.data.data.list
                    this.total = res.data.data.totalCount
                    this.loadingTable = false
                })
            })
        },
        handleTempurateInput(event) {
            this.tableData.filter(i => i.temperature).forEach(data => {
                if (Number.parseFloat(data.temperature) > event) {
                    data.temperatureState = '0'
                } else {
                    data.temperatureState = '1'
                }
            })

        },

        changeBtnFormType() {
            this.formData.btnFormType = !this.formData.btnFormType;
            setTimeout(() => {
                this.tableConfig.height = document.body.clientHeight - document.getElementById('table').offsetTop - 65
            }, 200)
        },

        // 获取地点信息
        getTreeData() {
            const temperatureMonitoringModel = new TemperatureMonitoringModel()
            temperatureMonitoringModel.getSchoolAddressList({schoolId: this.schoolId}).then((res) => {
                this.handleRes(res, () => {
                    this.formData.data.forEach((item) => {
                        if (item.key === 'addressName') {
                            item.list = res.data.data
                        }
                    })
                })
            })
        },
        // 获取部门信息
        getOrganData() {
            const temperatureMonitoringModel = new TemperatureMonitoringModel()
            temperatureMonitoringModel.getSchoolOrganList({schoolId: this.schoolId}).then((res) => {
                this.handleRes(res, () => {
                    this.formData.data.forEach((item) => {
                        if (item.key === 'organId') {
                            item.list = res.data.data.list.map(i => ({label: i.name, value: i.id}))
                            item.list.unshift({label: '全部', value: ''})
                        }
                    })
                })
            })
        },


        // 导出
        exportAll() {
            let obj = {
                schoolId: this.schoolId
            }
            // 表格选中数据
            if (this.tableListSel.length > 0) {
                obj.ids = this.tableListSel.map(i => i.id)
                obj.identification = 2
            } else {
                let filter = this.formData.data.filter(i => i.value !== '')
                // 未选中筛选条件，导出全部
                if (filter.length === 0) {
                    obj.identification = 3
                } else {
                    obj.identification = 1
                    // 选中筛选
                    filter.forEach(item => {
                        if (item.key === 'dateRange') {
                            obj.startTime = item.value[0]
                            obj.endTime = item.value[1]
                        } else {
                            obj[item.key] = item.value;
                        }
                    })
                }
            }
            downloadFile({url: '/school/schoolTemperature/export', form: obj}, () => {
                this.$message.success('导出成功')
            }, () => {

            })
        },
        // 选中
        handleSelectionChange(data) {
            this.tableListSel = data;
        },
        handleTreeClick(data) {
            if (data.data.child && data.data.child.length === 0) {
                data.dt.value = data.data.addressName
                this.listQuery.addressId = data.data.id
                data.selRef.blur()
            }
        },
        handleTreeClear() {
            this.listQuery.addressId = ''
        },
        clickBtn(ev) {
            switch (ev.item.fn) {
                case 'primary': // 查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    })
                    if (this.listQuery.dateRange && this.listQuery.dateRange.length > 0) {
                        this.listQuery.startTime = this.listQuery.dateRange[0] + ' 00:00:00'
                        this.listQuery.endTime = this.listQuery.dateRange[1] + ' 23:59:59'
                    } else {
                        this.listQuery.startTime = ''
                        this.listQuery.endTime = ''
                    }
                    this.getList(1);
                    break;
                case 'reset': // 重置
                    this.formData.data.forEach((item) => {
                        item.value = '';
                        this.listQuery[item.key] = ''
                    })
                    this.listQuery.addressId = ''
                    this.listQuery.startTime = ''
                    this.listQuery.endTime = ''
                    this.getList(1);
                    break;
            }
        },
        // 表格动态调整高度
        changeTableHeight() {
            setTimeout(() => {
                this.tableConfig.height = document.body.clientHeight - document.getElementById('table').offsetTop - 63
                window.onresize = debounce(() => {
                    if (!document.getElementById('table')) return
                    const height = document.body.clientHeight - document.getElementById('table').offsetTop - 63
                    this.tableConfig.height = height.toString()
                }, 200)
            }, 200)
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === '200') {
                fn()
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg)
            }
        },
    }
}
</script>

<style scoped lang="scss">
.right-panel {
    padding: 10px;
}


.opt-btn {
    margin-top: 10px;
    padding-top: 10px;
}

.text-div {
    display: flex;
    flex-direction: row;
}

.normal-text {
    margin-top: 3px;
    font-size: 14px;
    color: #606266;
}
</style>
